import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'

import { createPinia } from '@kidzonet/pinia'
import SentryInit from '@kidzonet/vue3/sentry'
import App from '@kidzonet/naiveui-app-root'
import '@kidzonet/chunk-loading-error-listener'
import initializeAuthAPI from '@kidzonet/dashboard-api-auth'

// @ts-expect-error strange import problems
import i18n from '@kidzonet/vue-i18n-package/obj.d.ts'
import router from './router'
import VuetifyPlugin from './vuetify'

initializeAuthAPI()

const pinia = createPinia()

const app = createApp(App)

app.use(pinia as any)
app.use(Notifications)
app.use(VuetifyPlugin)

app.use(router)
app.use(i18n)

app.mount('#app')

SentryInit(app, 'control', router)
