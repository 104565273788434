import type { Middleware } from 'openapi-fetch'
import * as Sentry from '@sentry/vue'
import { clearBrowser } from '@kidzonet/utils/src/logout'
import { notify } from '@kyvg/vue3-notification'
// @ts-expect-error strange import problems
import i18n from '@kidzonet/vue-i18n-package/obj.d.ts'

import AuthMiddlewareFactory, { TokenDetailsInterface, skipErrorHandling } from '@kidzonet/ts-api-refresh-token-middleware'

const MAX_TRIES = 20
const TRY_TIMEOUT = 1000

export const clearPending = () => {
    localStorage.setItem('kidzonet-refresh-token-pending', 'false')
    localStorage.setItem('kidzonet-child-refresh-token-pending', 'false')
}

export const getAccessToken = () => {
    return localStorage.getItem('kidzonet-child-token')
      || localStorage.getItem('kidzonet-token')
}

export const getExpiresIn = () => {
    const result = localStorage.getItem('kidzonet-child-token-expires-in')
      || localStorage.getItem('kidzonet-token-expires-in') || 0
    return Number(result)
}

export const getRefreshTokenPendingStatus = () => {
    return localStorage.getItem('kidzonet-child-refresh-token-pending') === 'true'
      || localStorage.getItem('kidzonet-refresh-token-pending') === 'true'
}

export const startRefreshTokenUpdate = () => {
    if (localStorage.getItem('kidzonet-child-refresh-token')) {
        localStorage.setItem('kidzonet-child-refresh-token-pending', 'true')
    } else {
        localStorage.setItem('kidzonet-refresh-token-pending', 'true')
    }
}

export const checkRefreshTokenPending = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(getRefreshTokenPendingStatus())
        }, TRY_TIMEOUT)
    })
}

export const isTokenExpired = () => {
    return (new Date().getTime() / 1000) - 60 >= getExpiresIn()
}

export const waitForRefreshTokenWillBeUpdated = async (count = MAX_TRIES): Promise<any> => {
    if (count > 0) {
        const result = await checkRefreshTokenPending()
        if (result) {
            return waitForRefreshTokenWillBeUpdated(count - 1)
        }
    }
}

export const getRefreshToken = () => {
    return localStorage.getItem('kidzonet-child-refresh-token')
      || localStorage.getItem('kidzonet-refresh-token')
}

export const mindcontrol = ({ access_token, refresh_token, expires_in }: TokenDetailsInterface) => {
    localStorage.setItem('kidzonet-child-token', access_token)
    localStorage.setItem('kidzonet-child-refresh-token', refresh_token)
    localStorage.setItem('kidzonet-child-token-expires-in', expires_in)
    localStorage.setItem('kidzonet-child-refresh-token-pending', 'false')
}

export const saveRefreshedToken = ({ access_token, refresh_token, expires_in }: TokenDetailsInterface) => {
    if (localStorage.getItem('kidzonet-child-refresh-token')) {
        mindcontrol({ access_token, refresh_token, expires_in })
    } else {
        localStorage.setItem('kidzonet-token', access_token)
        localStorage.setItem('kidzonet-refresh-token', refresh_token)
        localStorage.setItem('kidzonet-token-expires-in', expires_in)
        localStorage.setItem('kidzonet-refresh-token-pending', 'false')
    }
}

export const AuthMiddleware = AuthMiddlewareFactory({
    isTokenExpired,
    getRefreshTokenPendingStatus,
    startRefreshTokenUpdate,
    waitForRefreshTokenWillBeUpdated,
    getAccessToken,
    getRefreshToken,
    saveRefreshedToken,
    clearPending,
})

export const ErrorHandlingMiddleware: Middleware = {
    async onResponse ({ response, options }) {
        if (response.status < 400) {
            return response
        }
        const data = await response.clone().json()
        const endpoint = response.url.substr(options.baseUrl.length)
        if (skipErrorHandling(endpoint, data.status || response.status)) {
            return
        }
        if (response.status === 401) {
            clearBrowser()
            return
        }

        const e = data?.detail?.[0] || {}

        const { t } = i18n.global
        notify({
            type: 'error',
            title: t('errors.error'),
            text: t('errors.server_error'),
        })
        if (
            (e?.error?.message && e?.error?.message?.indexOf?.('network is offline') > -1)
            || (e?.message && e?.message?.indexOf?.('network is offline') > -1)
            || e?.name === 'ChunkLoadError'
            || e?.error?.name === 'ChunkLoadError'
            || e?.body?.detail === 'Token expired'
            || response.status === 500
        ) {
            return
        }
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(e?.body?.message || e)
        }
    },
}
