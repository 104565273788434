const LoginLayout = () => import('@kidzonet/login-layout')
const extLogin = () => import('@kidzonet/login-ext-login')
const SSO = () => import('@kidzonet/sso-login')

export default [{
    path: '/',
    component: LoginLayout,
    children: [{
        path: '',
        name: 'sso',
        component: SSO,
    }],
}, {
    path: '/ext-login/:t',
    component: extLogin,
    props: true,
    name: 'ext-login',
}]
