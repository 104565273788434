import { getVuetifyTheme } from './vuetifyTheme'

const primary = '#315992'
const secondary = '#F2A34D'
const theme = getVuetifyTheme(primary)
theme.themes.light.colors.mainContentBackground = '#F3F3F3'
theme.themes.light.colors.mainMenuActive = '#fff'
theme.themes.dark.colors.mainMenuActive = '#fff'
theme.themes.light.colors.mainMenuText = '#EAEEF4'
theme.themes.light.colors.mainMenuActiveText = '#315992'
theme.themes.dark.colors.mainMenuText = '#fff'
theme.themes.light.colors.secondary = secondary
theme.themes.light.colors.mainMenuIcon = secondary
theme.themes.dark.colors.mainMenuIcon = secondary
theme.themes.light.colors.tableAccent = secondary

export default theme
