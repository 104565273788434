import * as Sentry from '@sentry/vue'

import { sendBCLogoutMessage } from './broadCastChannels'

export const clearBrowser = (location = '/') => {
    if (localStorage.getItem('kidzonet-child-token')) {
        localStorage.removeItem('kidzonet-child-token')
        localStorage.removeItem('kidzonet-child-role')
        localStorage.removeItem('kidzonet-child-token-expires-in')
        localStorage.removeItem('kidzonet-child-refresh-token')
        localStorage.removeItem('kidzonet-child-refresh-token-pending')
    } else {
        localStorage.removeItem('kidzonet-token')
        localStorage.removeItem('kidzonet-role')
        localStorage.removeItem('kidzonet-token-expires-in')
        localStorage.removeItem('kidzonet-refresh-token')
        localStorage.removeItem('kidzonet-refresh-token-pending')
    }
    sendBCLogoutMessage()
    Sentry.setUser({})
    window.location.href = location
}
